<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12">
          <h3>
            Deal Information

            <v-progress-circular indeterminate color="primary" />
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12">
          <v-expansion-panels :value="0">
            <v-expansion-panel class="bordered">
              <v-expansion-panel-header>
                <h3>
                  Deal Information

                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: `/dashboard/admin/deal/edit/${dealData.id}`,
                      })
                    "
                  >
                    mdi-pencil-outline
                  </v-icon>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <p class="caption">Deal Information</p>

                    <v-simple-table dense>
                      <thead>
                        <th :width="250" />
                      </thead>

                      <tbody>
                        <tr>
                          <td>Public Name</td>

                          <td>
                            {{ dealData.public_name }}
                          </td>
                        </tr>

                        <tr>
                          <td>Internal Name</td>

                          <td>
                            {{ dealData.internal_name }}
                          </td>
                        </tr>

                        <tr>
                          <td>Description</td>

                          <td>
                            {{ dealData.description }}
                          </td>
                        </tr>

                        <tr>
                          <td>Deal Status</td>

                          <td>
                            {{ dealData.status }}
                          </td>
                        </tr>

                        <tr>
                          <td>Deal Type</td>

                          <td>
                            {{ dealData.deal_type }}
                          </td>
                        </tr>

                        <tr>
                          <td>Deal Purpose</td>

                          <td>
                            {{ dealData.deal_purpose }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <br />

                <v-row>
                  <v-col>
                    <v-btn @click="change_deal_to_draft">Change to Draft</v-btn>

                    &nbsp;<v-btn @click="archive_deal">Archive Deal</v-btn>

                    &nbsp;
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="12">
          <v-expansion-panels :value="1">
            <v-expansion-panel class="bordered">
              <v-expansion-panel-header>
                <h3>
                  Customers
                  <span
                    v-if="
                      dealData.customers == null ||
                      dealData.customers.length == 0
                    "
                  >
                    (please add customers)
                  </span>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row
                  v-if="
                    dealData.customers != null || dealData.customers.length != 0
                  "
                >
                  <v-col
                    cols="12"
                    v-for="customer in dealData.customers"
                    :key="customer.id"
                  >
                    <p class="caption">
                      Customer Info
                      <v-icon
                        class="ml-4"
                        style="cursor: pointer"
                        small
                        @click="
                          $router.push({
                            path: `/dashboard/admin/customer/edit/${customer.id}`,
                          })
                        "
                      >
                        mdi-pencil-outline
                      </v-icon>
                    </p>

                    <v-simple-table dense>
                      <thead>
                        <th :width="250" />
                        <th />
                      </thead>

                      <tbody>
                        <tr>
                          <td>Name</td>

                          <td>
                            {{ customer.first_name }} {{ customer.last_name }}
                          </td>
                        </tr>

                        <tr>
                          <td>Email</td>

                          <td>
                            {{ customer.email }}
                          </td>
                        </tr>

                        <tr>
                          <td>Phone</td>

                          <td>
                            {{ customer.phone }}
                          </td>
                        </tr>

                        <tr>
                          <td>Main Applicant</td>

                          <td>
                            {{ customer.main_applicant }}
                          </td>
                        </tr>

                        <tr>
                          <td>Co-Applicant</td>

                          <td>
                            {{ customer.co_applicant }}
                            {{
                              customer.co_applicant_relationship_to_main_applicant
                            }}
                            {{ customer.co_applicant_type }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>

                  <v-col>
                    <v-btn
                      link
                      :to="{
                        path: `/dashboard/admin/deal/customer/add/${dealData.id}`,
                      }"
                    >
                      Add Customer
                    </v-btn>
                  </v-col>
                </v-row>
                <br />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12">
          <v-expansion-panels multiple>
            <v-expansion-panel
              v-if="dealData.deal_summaries == null"
              class="bordered"
            >
              <v-expansion-panel-header>
                <h3>
                  Deal Summary
                  <span>(please add deal summary)</span>

                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click.stop="addNote"
                  >
                    mdi-currency-usd
                  </v-icon>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <p>There is no deal summary available.</p>

                <div class="mt-4">
                  <v-btn
                    dark
                    @click="
                      $router.push({
                        path: `/dashboard/admin/deal_summary/add/${dealData.id}`,
                      })
                    "
                  >
                    Create Deal Summary
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="dealData.deal_summaries != null"
              class="bordered"
            >
              <v-expansion-panel-header>
                <h3>
                  Deal Summaries

                  <v-icon class="ml-4" style="cursor: pointer"
                    >mdi-currency-usd</v-icon
                  >
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-expansion-panels multiple>
                  <v-expansion-panel
                    v-for="(summary, summary_index) in dealData.deal_summaries"
                    :key="summary.id"
                    class="bordered"
                  >
                    <v-expansion-panel-header
                      :class="{
                        active_summary: summary.status == 'active',
                        inactive_summary: summary.status == 'archive',
                      }"
                    >
                      {{ summary.amount_requested | formatPrice }} - LTV:
                      {{ summary.loan_to_value }}% - Created:
                      {{ summary.createdAt | dateFormat }} - Status:
                      {{ summary.status }}
                      <span style="margin-left: 30px">
                        <v-icon
                          class="ml-4"
                          style="cursor: pointer"
                          left
                          small
                          @click.prevent="
                            $router.push({
                              path: `/dashboard/admin/deal_summary/edit/${summary.id}`,
                            })
                          "
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-expansion-panels multiple>
                        <v-expansion-panel class="bordered">
                          <v-expansion-panel-header>
                            Deal Summary Information
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table dense>
                              <thead />

                              <tbody>
                                <tr>
                                  <td style="width: 250px">ID</td>

                                  <td>
                                    {{ summary.id }}
                                  </td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Status</td>

                                  <td>
                                    {{ summary.status | capitalize }}
                                  </td>
                                </tr>

                                <tr>
                                  <td />
                                  <td />
                                </tr>

                                <tr>
                                  <td style="width: 250px">City</td>

                                  <td>
                                    {{
                                      summary.city | capitalize_first_letter
                                    }},
                                    {{ summary.province }}
                                  </td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Location Notes</td>

                                  <td>{{ summary.location_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Property Type</td>

                                  <td>{{ summary.property_type }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Property Value</td>

                                  <td>
                                    {{ summary.property_value | formatPrice }}
                                  </td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Property Value Notes
                                  </td>

                                  <td>{{ summary.property_value_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px"></td>

                                  <td />
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Owing Against Property
                                  </td>

                                  <td>
                                    {{ summary.owing_against | formatPrice }}
                                  </td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Owing Against Property Notes
                                  </td>

                                  <td>{{ summary.owing_against_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px"></td>

                                  <td />
                                </tr>

                                <tr>
                                  <td style="width: 250px">Amount Requested</td>

                                  <td>
                                    {{ summary.amount_requested | formatPrice }}
                                  </td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Amount Requested Notes
                                  </td>

                                  <td>
                                    {{ summary.amount_requested_notes }}
                                  </td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Loan To Value</td>

                                  <td>{{ summary.loan_to_value }}%</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Mortgage Position
                                  </td>

                                  <td>{{ summary.mortgage_position }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px"></td>

                                  <td />
                                </tr>

                                <tr>
                                  <td style="width: 250px">Income Notes</td>

                                  <td>{{ summary.income_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Liability Notes</td>

                                  <td>{{ summary.liability_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Credit Notes</td>

                                  <td>{{ summary.credit_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Exit Strategy</td>

                                  <td>{{ summary.exit_strategy }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Summary Notes</td>

                                  <td>{{ summary.summary_notes }}</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">GDS</td>

                                  <td>{{ summary.GDS }}%</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">TDS</td>

                                  <td>{{ summary.TDS }}%</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px"></td>

                                  <td />
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Proposed Interest Rate
                                  </td>

                                  <td>{{ summary.proposed_rate }}%</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">
                                    Proposed Lender Fee
                                  </td>

                                  <td>{{ summary.proposed_lender_fee }}%</td>
                                </tr>

                                <tr>
                                  <td style="width: 250px">Proposed Term</td>

                                  <td>{{ summary.proposed_term }} Months</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="bordered">
                          <v-expansion-panel-header>
                            Offers ( {{ summary.summary_offers.length }} )
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-simple-table id="offer_table" dense>
                              <thead>
                                <th :width="250">Created</th>

                                <th>Lender</th>

                                <th>Amount</th>

                                <th>Interest Rate</th>

                                <th>Lender Fee</th>

                                <th>Position</th>

                                <th>Term</th>

                                <th>Credit</th>

                                <th>Income</th>

                                <th>Status</th>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="offer in summary.summary_offers"
                                  :key="offer.id"
                                  @click="
                                    open_summary_offer(summary.id, offer.id)
                                  "
                                >
                                  <td>{{ offer.createdAt | dateFormat }}</td>

                                  <td>{{ offer.company.name }}</td>

                                  <td>
                                    {{ offer.mortgage_amount | formatPrice }}
                                  </td>

                                  <td>{{ offer.interest_rate }}%</td>

                                  <td>{{ offer.lender_fee }}%</td>

                                  <td>{{ offer.mortgage_position }}</td>

                                  <td>{{ offer.mortgage_term }} Months</td>

                                  <td>
                                    {{ offer.credit_check | booleanString }}
                                  </td>

                                  <td>
                                    {{
                                      offer.income_verification | booleanString
                                    }}
                                  </td>

                                  <td>{{ offer.status }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="bordered">
                          <v-expansion-panel-header>
                            Assigned Lenders
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-simple-table dense>
                              <thead>
                                <th>Assigned</th>

                                <th>Lender</th>

                                <th>User</th>

                                <th>Viewed</th>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="assigned in summary.deal_summary_access_links"
                                  :key="assigned.id"
                                >
                                  <td>{{ assigned.createdAt | dateFormat }}</td>

                                  <td>{{ assigned.company.name }}</td>

                                  <td>
                                    {{ assigned.user.first_name }}
                                    {{ assigned.user.last_name }}
                                  </td>

                                  <td>
                                    {{
                                      last_viewed(
                                        assigned.user.id,
                                        summary_index
                                      )
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>

                            <br />

                            <br />

                            <v-btn
                              v-if="summary.status == 'active'"
                              link
                              :to="{
                                path: `/dashboard/admin/deal/assign_deal_summary/${summary.id}`,
                              }"
                            >
                              Assign Lenders
                            </v-btn>

                            <v-btn
                              class="ml-5"
                              v-if="summary.status == 'active'"
                              link
                              :to="{
                                path: `/dashboard/admin/deal/resend_to_lenders/${summary.id}`,
                              }"
                            >
                              Resend to Lenders
                            </v-btn>

                            <p v-else>
                              Deal summary must be active to assign lenders to
                              deal summary
                            </p>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="bordered">
                          <v-expansion-panel-header>
                            Lender View Log
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-simple-table dense>
                              <thead>
                                <th>Viewed</th>

                                <th>Lender</th>

                                <th>User</th>

                                <th>Type</th>
                              </thead>

                              <tbody>
                                <tr
                                  v-for="view in summary.summary_view_logs"
                                  :key="view.id"
                                >
                                  <td>
                                    {{
                                      new Date(view.createdAt).toLocaleString()
                                    }}
                                  </td>

                                  <td>{{ view.company.name }}</td>

                                  <td>
                                    {{ view.user.first_name }}
                                    {{ view.user.last_name }}
                                  </td>

                                  <td>
                                    {{ view.type }}
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>

                            <br />

                            <br />

                            <v-btn
                              link
                              :to="{
                                path: `/dashboard/admin/deal/assign_deal_summary/${summary.id}`,
                              }"
                            >
                              Assign Lenders
                            </v-btn>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br /><br />
                <v-btn
                  dark
                  @click="
                    $router.push({
                      path: `/dashboard/admin/deal_summary/add/${dealData.id}`,
                    })
                  "
                >
                  Create New Deal Summary
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <!-- deal files section -->
      <v-row justify="center" class="mt-5">
        <v-col xs="12">
          <v-expansion-panels multiple>
            <v-expansion-panel class="bordered">
              <v-expansion-panel-header>
                <h3>
                  Deal Files
                  <v-icon class="ml-4">mdi-folder-open-outline</v-icon>
                </h3>
              </v-expansion-panel-header>

              <DealFiles :dealData="dealData" ref="dealFiles" />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <!-- upload section -->
      <v-row justify="center" class="mt-5">
        <v-col xs="12">
          <v-expansion-panels multiple>
            <v-expansion-panel class="bordered">
              <v-expansion-panel-header>
                <h3>
                  Upload Files
                  <v-icon class="ml-4">mdi-cloud-upload-outline</v-icon>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <UploadFiles
                  :dealData="dealData"
                  @refreshDealFiles="refresh_deal_files"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <AdminOfferDetailsDialog
        @close_show_offer_details_dialog="show_offer_details_dialog = false"
        :dialog_offer_data="dialog_offer_data"
        :dialog_offer_summary_data="dialog_offer_summary_data"
        :show_offer_details_dialog="show_offer_details_dialog"
        @reload_page_data="reloadPageData"
      />
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';
import filt from '@/plugins/filters';
import { capitalize, dateFormat } from '../../../plugins/filters';

export default {
  name: 'DealRead',

  props: ['deal_id'],

  components: {
    // insertNote: () => import("@/components/note/InsertNote.vue"),
    // Note: () => import("@/components/note/Note.vue"),
    AdminOfferDetailsDialog: () =>
      import('@/components/AdminOfferDetailsDialog.vue'),
    DealFiles: () => import('@/components/DealFiles.vue'),
    UploadFiles: () => import('@/components/UploadFiles.vue'),
  },

  data() {
    return {
      panel: 0,
      show_offer_details_dialog: false,
      dialog_offer_data: null,
      dialog_offer_summary_data: null,
      loadingNotes: false,
      addNoteActive: false,
      formLoading: false,
      componentLoading: true,
      submitError: '',

      formRules: {
        valid: false,
        required: [(value) => !!value || 'This is a required field.'],
      },

      dealData: {},
      dealContacts: {},
      dealAddresses: {},
      dealNotes: {},
      dealAccounts: {},
      assignedLenders: {},
    };
  },

  methods: {
    last_viewed(user_id, summary_index) {
      let user_view_logs = this.dealData.deal_summaries[
        parseInt(summary_index)
      ].summary_view_logs.filter((el) => {
        if (el.user.id == user_id) {
          return el;
        }
      });

      if (user_view_logs.length) {
        return 'Viewed';
      } else {
        return 'Not Viewed';
      }
    },

    open_summary_offer(summary_id, offer_id) {
      let summary = this.dealData.deal_summaries.filter((summary) => {
        return summary.id == summary_id;
      });

      let offer = summary[0].summary_offers.filter((offer) => {
        return offer.id == offer_id;
      });

      this.dialog_offer_data = offer[0];
      this.dialog_offer_summary_data = summary[0];
      this.show_offer_details_dialog = true;
    },

    // close_show_offer_details_dialog(){
    //   alert('close_show_offer_details_dialog')
    //   this.show_offer_details_dialog = false;
    // },

    async archive_deal() {
      try {
        let archived_deal = await API().patch(
          `/api/internal-admin/archive_deal/?deal_id=${this.deal_id}`
        );
        this.loadPageData();
      } catch (error) {
        alert(error);
      }
    },

    async change_deal_to_draft() {
      try {
        let archived_deal = await API().patch(
          `/api/internal-admin/change_deal_to_draft/?deal_id=${this.deal_id}`
        );
        this.loadPageData();
      } catch (error) {
        alert(error);
      }
    },

    async unaward(lenderId) {
      let unawardConfirm = confirm(
        'Are you sure you want to unaward the deal?'
      );

      if (unawardConfirm) {
        let dealUnaward = await API().post('/dealUnaward', {
          dealId: this.dealId,
          lenderId: lenderId,
        });

        if (dealUnaward.status == 200) {
          this.loadPageData();
        } else {
          alert('the deal was not awarded, there was an error');
        }
      } else {
        alert('you did not award it');
      }
    },

    async awardDeal(lenderId) {
      let awarded = confirm('Are you sure you want to award the deal?');
      if (awarded) {
        let dealAward = await API().post('/dealAward', {
          dealId: this.dealId,
          lenderId: lenderId,
        });

        if (dealAward.status == 200) {
          this.loadPageData();
        } else {
          alert('the deal was not awarded, there was an error');
        }
      } else {
        alert('you did not award it');
      }
    },

    async toggleLenderActive(index) {
      this.$set(this.assignedLenders[index], 'loading', true);
      // this.assignedLenders[index].loading = true;

      let modifyLenderActive = await API().patch('/lenderDealPivotActive', {
        id: this.assignedLenders[index].id,
        active: this.assignedLenders[index].active,
      });

      if (modifyLenderActive.status == 202) {
        console.log('the status is 202');
        this.assignedLenders[index].loading = false;
      } else {
        alert(
          'there was an error updating the status!!! status was not updated'
        );
      }
    },

    async refreshNotes() {
      console.log('refresh notes firing read');
      await API()
        .get(`/dealNotes/${this.dealId}`)
        .then((res) => {
          this.dealNotes = res.data;
          this.loadingNotes = false;
          this.closeNote();
        })
        .catch((e) => {
          console.log('the catch block in refreshnotes is firing');
          console.log(e);
        });
    },

    addNote() {
      this.addNoteActive = true;
    },

    closeNote() {
      this.addNoteActive = false;
    },

    submitForm() {
      this.loading = true;
      this.submitError = '';
      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post('/deal/add', this.dealData)
          .then((res) => {
            this.loading = false;

            alert(JSON.stringify(res.data));
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },

    loadPageData() {
      this.componentLoading = true;
      API()
        .get(`api/internal-admin/dealRead/${this.$route.params.deal_id}`)
        .then((res) => {
          this.dealData = res.data;

          this.componentLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    reloadPageData() {
      API()
        .get(`api/internal-admin/dealRead/${this.$route.params.deal_id}`)
        .then((res) => {
          this.dealData = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // this is to refresh deal files list after uploading a file
    refresh_deal_files() {
      this.$refs.dealFiles.getDealFile();
    },
  },

  mounted() {
    // console.log(filt)
    this.loadPageData();
  },
  filters: {
    ...filt,
  },
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
#offer_table tr:hover {
  cursor: pointer !important;
  td {
    background-color: rgb(77, 77, 77);
  }
}
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.active_summary {
  color: green;
}

.inactive_summary {
  color: red;
}
</style>
